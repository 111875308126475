'use client';

import { useState, useEffect } from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import { useAtom } from 'jotai';
import Nav from '@/app/components/Nav';
import Footer from '@/app/components/Footer';
import LockScreen from './components/LockScreen';
import { useInitializeDelegates } from '@/app/hooks/useInitializeDelegates';
import { config } from '@/app/connection/config';
import { useGetProfile } from '@/app/hooks/Database/useGetProfile';
import { fetchTokenInfoAtom } from '@/app/atoms/token';
import { useFetchProposals } from './hooks/useFetchProposals';
import { useFetchGovernorData } from './hooks/useFetchGovernorData';
import { postMadeAtom } from '@/app/atoms/users';
import { useWebSocket } from '@/app/hooks/useWebSocket';

export default function ClientLayout({ children }: { children: React.ReactNode }) {
  const [password, setPassword] = useState('');
  const { address, isConnected } = useAccount();
  const [isLocked, setIsLocked] = useState(false);
  const { disconnect } = useDisconnect({ config });
  const [postMade] = useAtom(postMadeAtom);
  const [, fetchTokenInfo] = useAtom(fetchTokenInfoAtom);

  useFetchProposals();
  useInitializeDelegates();
  useFetchGovernorData();
  useGetProfile(false, postMade);
  useWebSocket();

  useEffect(() => {
    if (isConnected && address) {
      fetchTokenInfo(address as `0x${string}`);
    } else {
      fetchTokenInfo();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [address, isConnected]);

  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      window.ethereum.on('accountsChanged', (accounts: string[]) => {
        disconnect();
      });

      window.ethereum.on('chainChanged', (chainId: number) => {
        disconnect();
      });

      return () => {
        window.ethereum?.removeListener('accountsChanged', disconnect);
        window.ethereum?.removeListener('chainChanged', disconnect);
      };
    }
  }, [disconnect]);

  return (
    <>
      {isLocked ? (
        <LockScreen
          password={password}
          setPassword={setPassword}
          setIsLocked={setIsLocked}
        />
      ) : (
        <>
          <div className="flex flex-col min-h-screen">
            <Nav />
            <main className="flex-grow flex flex-col">
              <section className="flex flex-col mx-0 xs:mx-4 sm:mx-16 lg:mx-24 xxl:mx-64 no-scrollbar">
                {children}
              </section>
            </main>
            <Footer />
          </div>
        </>
      )}
    </>
  );
}
