'use client';

import { DelegateInfo } from '@/app/types/delegates';

export const fetchDelegatesAndBalances = async (): Promise<DelegateInfo[]> => {
  try {
    const response = await fetch(process.env.NEXT_PUBLIC_DELEGATES_API!);

    if (!response.ok) {
      throw new Error(`Error fetching delegates: ${response.statusText}`);
    }

    const delegates = await response.json();

    return delegates.map((delegate: any) => ({
      address: delegate.address,
      tokenBalance: delegate.tokenBalance,
      votingPower: delegate.votingPower,
      delegationsReceived: delegate.delegationsReceived,
      delegators: delegate.delegators,
    }));
  } catch (error) {
    console.error(`Error in fetchDelegatesAndBalances:`, error);
    throw error;
  }
};
