import { atom, useAtomValue } from 'jotai';
import {
  getTokenBalanceOf,
  getTokenSymbol,
  getTokenTotalSupply,
} from '@/app/helpers/contracts/token/read';

import { formatUnits } from 'ethers';
import { abbreviateValue } from '@/app/helpers/abbreviateValue';
import { circulatingSupplyAtom } from './governorContract';

interface TokenInfo {
  balance: string;
  symbol: string;
  totalSupply: string;
  circulatingSupply: string | null;
}

export const tokenInfoAtom = atom<TokenInfo>({
  balance: '0.00',
  symbol: '',
  totalSupply: '0.00',
  circulatingSupply: '0.00',
});

export const isLoadingTokenInfoAtom = atom<boolean>(false);
export const hasTokensAtom = atom((get) => parseFloat(get(tokenInfoAtom).balance) > 0);

export const fetchTokenInfoAtom = atom(
  null,
  async (get, set, address?: `0x${string}`) => {
    set(isLoadingTokenInfoAtom, true);
    try {
      let symbol = get(tokenInfoAtom).symbol;
      let totalSupply = get(tokenInfoAtom).totalSupply;

      if (!symbol || !totalSupply || totalSupply === '0.00') {
        symbol = await getTokenSymbol();
        const totalSupplyRaw = await getTokenTotalSupply();
        totalSupply = parseFloat(formatUnits(totalSupplyRaw, 18)).toLocaleString();
      }

      let formattedBalance = '0.00';

      if (address) {
        const { balance, decimals } = await getTokenBalanceOf(address);
        const rawBalance = parseFloat(formatUnits(balance, decimals));

        let formattedBalanceString;
        if (rawBalance > 999_999) {
          formattedBalanceString = abbreviateValue(rawBalance);
        } else {
          formattedBalanceString = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(rawBalance);
        }

        if (formattedBalanceString.includes('.')) {
          formattedBalanceString = formattedBalanceString.replace(/\.?0+$/, '');
        }

        formattedBalance = formattedBalanceString;
      }

      set(tokenInfoAtom, {
        balance: formattedBalance,
        symbol,
        totalSupply,
        circulatingSupply: get(circulatingSupplyAtom),
      });
    } catch (error) {
      console.error('Failed to fetch token info:', error);
      set(tokenInfoAtom, {
        balance: '0.00',
        symbol: '',
        totalSupply: '0.00',
        circulatingSupply: '0.00',
      });
    } finally {
      set(isLoadingTokenInfoAtom, false);
    }
  },
);
