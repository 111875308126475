export async function crank(): Promise<void> {
  try {
    const response = await fetch(process.env.NEXT_PUBLIC_API_CRANK!, {
      method: 'GET',
      cache: 'no-store',
    });

    if (!response.ok) {
      console.error(`Failed crank fetch: ${response.status} - ${response.statusText}`);
    }
  } catch (error) {
    console.error('An error occurred while trying to trigger the crank:', error);
  }
}
