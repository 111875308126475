'use client';

import { useEffect } from 'react';
import { useSetAtom } from 'jotai';
import {
  constitutionalQuorumAtom,
  fundingQuorumAtom,
  votingPeriodAtom,
  votingDelayAtom,
  circulatingSupplyAtom,
  tokenAddressAtom,
  timelockAddressAtom,
  proposalThresholdAtom,
  constitutionalQuorumRawAtom,
  fundingQuorumRawAtom,
  rawCirculatingSupplyAtom,
} from '@/app/atoms/governorContract';
import {
  fetchQuorum,
  fetchQuorumNumerator,
  fetchQuorumDenominator,
  fetchVotingPeriod,
  fetchVotingDelay,
  fetchTokenAddress,
  fetchTimelockAddress,
  fetchCirculatingSupply,
  fetchProposalThreshold,
} from '@/app/helpers/contracts/governor/read';

export const useFetchGovernorData = () => {
  const setConstitutionalQuorum = useSetAtom(constitutionalQuorumAtom); // Constitutional Quorum (formatted)
  const setFundingQuorum = useSetAtom(fundingQuorumAtom); // Funding Quorum (formatted)
  const setVotingPeriod = useSetAtom(votingPeriodAtom);
  const setVotingDelay = useSetAtom(votingDelayAtom);
  const setCirculatingSupply = useSetAtom(circulatingSupplyAtom);
  const setTokenAddress = useSetAtom(tokenAddressAtom);
  const setTimelockAddress = useSetAtom(timelockAddressAtom);
  const setProposalThreshold = useSetAtom(proposalThresholdAtom);
  const setConstitutionalQuorumRaw = useSetAtom(constitutionalQuorumRawAtom); // Raw Constitutional Quorum
  const setFundingQuorumRaw = useSetAtom(fundingQuorumRawAtom); // Raw Funding Quorum
  const setRawCirculatingSupply = useSetAtom(rawCirculatingSupplyAtom); // Raw Circulating Supply

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch all required data in parallel
        const [
          quorum,
          quorumNumerator,
          quorumDenominator,
          votingPeriodData,
          votingDelayData,
          tokenAddress,
          timelockAddress,
          circulatingSupplyData,
          proposalThreshold,
        ] = await Promise.all([
          fetchQuorum(),
          fetchQuorumNumerator(),
          fetchQuorumDenominator(),
          fetchVotingPeriod(),
          fetchVotingDelay(),
          fetchTokenAddress(),
          fetchTimelockAddress(),
          fetchCirculatingSupply(),
          fetchProposalThreshold(),
        ]);

        // Parse the circulating supply
        const circulatingSupplyValue = parseFloat(
          circulatingSupplyData.circulatingSupply.replace(/,/g, ''),
        );

        // Calculate Constitutional Quorum as a fraction of circulating supply
        const constitutionalQuorum = (
          (circulatingSupplyValue * parseFloat(quorum)) /
          100
        ).toFixed(2);

        // Calculate Funding Quorum using numerator and denominator
        const fundingQuorum = (
          (circulatingSupplyValue * parseFloat(quorumNumerator)) /
          parseFloat(quorumDenominator)
        ).toFixed(2);

        // Update atoms with fetched data
        setConstitutionalQuorum(constitutionalQuorum);
        setFundingQuorum(fundingQuorum);
        setVotingPeriod(votingPeriodData.formattedVotingPeriod);
        setVotingDelay(votingDelayData.formattedVotingDelay);
        setCirculatingSupply(circulatingSupplyValue.toLocaleString());
        setTokenAddress(tokenAddress);
        setTimelockAddress(timelockAddress);
        setProposalThreshold(proposalThreshold);
        setConstitutionalQuorumRaw(quorum);
        setFundingQuorumRaw({
          numerator: quorumNumerator,
          denominator: quorumDenominator,
        });
        setRawCirculatingSupply(circulatingSupplyData.circulatingSupply);
      } catch (error) {
        console.error('Error fetching governor data:', error);
      }
    };

    fetchData();
  }, [
    setConstitutionalQuorum,
    setFundingQuorum,
    setVotingPeriod,
    setVotingDelay,
    setCirculatingSupply,
    setTokenAddress,
    setTimelockAddress,
    setProposalThreshold,
    setConstitutionalQuorumRaw,
    setFundingQuorumRaw,
    setRawCirculatingSupply,
  ]);
};
