import { getCsrfToken, signIn, signOut, getSession } from 'next-auth/react';
import type {
  SIWEVerifyMessageArgs,
  SIWECreateMessageArgs,
  SIWESession,
} from '@web3modal/siwe';
import { createSIWEConfig, formatMessage } from '@web3modal/siwe';
import { mainnet, sepolia, Chain } from 'wagmi/chains';

// Determine the chain based on the environment variable
const chainName = process.env.NEXT_PUBLIC_CHAIN;

let selectedChain: Chain | undefined;

if (chainName === 'mainnet') {
  selectedChain = mainnet;
} else if (chainName === 'sepolia') {
  selectedChain = sepolia;
} else {
  throw new Error('Unsupported or undefined chain specified in NEXT_PUBLIC_CHAIN');
}

export const siweConfig = createSIWEConfig({
  getMessageParams: async () => ({
    domain: typeof window !== 'undefined' ? window.location.host : '',
    uri: typeof window !== 'undefined' ? window.location.origin : '',
    chains: [selectedChain.id],
    statement: 'Please sign with your account',
  }),
  createMessage: ({ address, ...args }: SIWECreateMessageArgs) =>
    formatMessage(args, address),
  getNonce: async () => {
    const nonce = await getCsrfToken();
    if (!nonce) {
      throw new Error('Failed to get nonce!');
    }
    return nonce;
  },
  enabled: true,
  getSession: async () => {
    const session = await getSession();

    if (!session) {
      throw new Error('Failed to get session!');
    }

    const { address, chainId } = session as unknown as SIWESession;

    return { address, chainId };
  },
  verifyMessage: async ({ message, signature }: SIWEVerifyMessageArgs) => {
    try {
      const success = await signIn('credentials', {
        message,
        redirect: false,
        signature,
        callbackUrl: '/protected',
      });

      return Boolean(success?.ok);
    } catch (error) {
      return false;
    }
  },
  signOut: async () => {
    try {
      await signOut({
        redirect: false,
      });

      return true;
    } catch (error) {
      return false;
    }
  },
});
