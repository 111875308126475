'use client';

import React, { useState, useRef, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { NavAvatar } from '@/app/components/NavAvatar';
import Dropdown from './Dropdown';
import { useAccount } from 'wagmi';

const Profile = () => {
  const { isConnected, address } = useAccount();
  const [isHovered, setIsHovered] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [dropdownWidth, setDropdownWidth] = useState(0);

  useEffect(() => {
    const currentRef = dropdownRef.current;
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setDropdownWidth(entry.contentRect.width);
      }
    });

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      onMouseLeave={() => setIsHovered(false)}
      onMouseEnter={() => setIsHovered(true)}
      className={twMerge('group flex flex-col items-center mt-4')}
    >
      <div
        className={twMerge(
          `flex items-center rounded-t-md sm:max-h-[50px] min-w-[120px] py-2 text-[12px] whitespace-nowrap`,
          isConnected && isHovered && 'bg-neutral-800',
        )}
      >
        {isConnected && (
          <>
            <NavAvatar />
            <Dropdown isVisible={isHovered} width={dropdownWidth} />
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
