import { atom } from 'jotai';
import {
  ProposalCard,
  DatabaseProposal,
  defaultProposalCard,
  Vote,
} from '@/app/types/proposals';

export const initialProposalsAtom = atom<ProposalCard[]>([]);

export const proposalsLoadingAtom = atom(false);

export const allProposalsAtom = atom<ProposalCard[]>([]);

export const passedProposalsAtom = atom<ProposalCard[]>([]);

export const submitProposalAtom = atom<ProposalCard>(defaultProposalCard);

export const proposalCountAtom = atom({
  total: 0,
  passed: 0,
  failed: 0,
});

export const totalVotesAtom = atom(0);

//!DB LOGIC
export const proposalDraftAtom = atom<DatabaseProposal>({
  id: 0,
  title: '',
  description: '',
  wallet_address: '',
  is_draft: true,
});

export const proposalDbAtom = atom<DatabaseProposal[]>([]);

//!DB LOGIC
export const draftSavedAtom = atom(false);

export const proposalVotesAtom = atom<Vote[]>([]);
export const votesLoadingAtom = atom(true);
