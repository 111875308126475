import { PlusIcon } from '@heroicons/react/16/solid';
import { ComponentType } from 'react';
import { Address } from 'viem';
import { InputVariant } from '../karratco/submit/proposal/types/inputTypes';

export interface ProposalCard {
  votes_abstain: string;
  votes_against: string;
  decodedCalldatas: {
    contract: string;
    functionName: string;
    params: {
      name: string;
      type: string;
      value: string;
    }[];
  }[];
  calldatas: {
    method: string;
    types: string[];
    values: any[];
    encodedData: string;
  }[];
  created_at: Date | null;
  description: string;
  votes_for: string;
  vote_start: Date | null;
  vote_end: Date | null;
  proposal_id: string;
  snapshot: string;
  state: ProposalState;
  title: string;
  votesWithReason?: any[];
  proposer: Address;
  quorum: string;
  targets: `0x${string}`[];
  voters: number;
  vals: string[];
  encodedCalldatas?: string[];
}

export type ProposalState = {
  text: string;
  bgColor: string;
  textColor: string;
  icon: ComponentType<React.SVGProps<SVGSVGElement>>;
};

export enum State {
  Succeeded = 'succeeded',
  Executed = 'executed',
  Active = 'active',
  Passed = 'passed',
  Defeated = 'defeated',
  Failed = 'failed',
  Pending = 'pending',
  Queued = 'queued',
  Canceled = 'canceled',
  Expired = 'expired',
  Unknown = 'unknown',
  Default = '',
}

export const defaultProposalCard: ProposalCard = {
  votes_abstain: '0',
  votes_against: '0',
  decodedCalldatas: [],
  calldatas: [
    {
      method: '',
      types: [],
      values: [],
      encodedData: '',
    },
  ],
  created_at: new Date(),
  description: '',
  votes_for: '0',
  vote_end: new Date(),
  vote_start: new Date(),
  proposal_id: '0123',
  snapshot: '',
  state: {
    text: '',
    bgColor: '',
    textColor: '',
    icon: PlusIcon,
  },
  title: '',
  votesWithReason: [],
  proposer: '0x' as Address,
  quorum: '',
  targets: ['0x' as Address],
  voters: 0,
  vals: [''],
  encodedCalldatas: [],
};

export interface DatabaseProposal {
  id?: number;
  title?: string;
  description?: string;
  is_draft?: boolean;
  wallet_address?: string;
}
export interface Proposal {
  proposalId: string;
  title?: string;
  fullProposalId?: string;
  description: string;
  is_draft?: boolean;
  walletAddress?: `0x${string}`;
  targets: `0x${string}`[];
  values: string[];
  calldatas: string[];
  proposalVotes: any;
  forVotes: string;
  againstVotes: string;
  abstainVotes: string;
  votesWithReason?: any[];
  statusBgColor: string;
  statusTextColor: string;
  status: string;
  createdAt: Date;
  proposedBy: `0x${string}`;
}

export interface ActionInput {
  name: string;
  type: string;
  internalType?: string;
  value?: string | string[];
}

export interface Action {
  id: string;
  target: `0x${string}`;
  method: string;
  show: string;
  inputs: MethodInput[];
}

export interface DatabaseProposal {
  id?: number;
  proposalId?: string;
  title?: string;
  description?: string;
  is_draft?: boolean;
  wallet_address?: string;
}

export type ProposalMapping = {
  [key: string]: {
    title: string;
    description: string;
  };
};

export type VoteType = 'yes' | 'no' | 'abstain';

export const enum VoteEnum {
  YES = 'yes',
  NO = 'no',
  ABSTAIN = 'abstain',
}

export interface Vote {
  proposalId: string;
  address: Address;
  votingPower: number;
  vote: VoteType;
  date: Date;
  reason: string;
  proposer: Address;
}

export type ProposalType =
  | 'Process Modifications'
  | 'Protocol Upgrades'
  | 'Funding Requests'
  | 'Informational';

export interface MethodInput {
  id: string;
  internalType: string;
  name: string;
  show: string;
  type: string;
  value: string;
  internalValue: any;
  variant?: InputVariant;
}

export interface ContractMethod {
  name: string;
  show: string;
  description: string;
  inputs: MethodInput[];
}

export interface Contract {
  name: string;
  address: string;
  methods: ContractMethod[];
}

export interface ProposalCategory {
  type: ProposalType;
  name: string;
  contracts: Contract[];
}

export interface ProposalFlow {
  proposal: {
    [key: string]: ProposalCategory;
  };
}
