'use client';

export const truncateAddressNav = (str: string, left: number = 4, right: number = 4) => {
  return str?.length > left + right
    ? str.slice(0, left) + '...' + str.slice(str.length - right, str.length)
    : str;
};

export const truncateAddress64 = (str: string, left: number = 6, right: number = 4) => {
  return str?.length > left + right
    ? str.slice(0, left) + '...' + str.slice(str.length - right, str.length)
    : str;
};

export const truncateAddressXs = (str: string, left: number = 4, right: number = 0) => {
  return str?.length > left + right
    ? str.slice(0, left) + '...' + str.slice(str.length - right, str.length)
    : str;
};

export const truncateAddressSm = (str: string, left: number = 4, right: number = 2) => {
  return str?.length > left + right
    ? str.slice(0, left) + '...' + str.slice(str.length - right, str.length)
    : str;
};

export const truncateAddressMd = (str: string, left: number = 8, right: number = 6) => {
  return str?.length > left + right
    ? str.slice(0, left) + '...' + str.slice(str.length - right, str.length)
    : str;
};

export const truncateAddressXl = (str: string, left: number = 12, right: number = 12) => {
  return str?.length > left + right
    ? str.slice(0, left) + '...' + str.slice(str.length - right, str.length)
    : str;
};
