'use client';

import { useEffect } from 'react';
import { useSetAtom, useAtomValue } from 'jotai';
import { useAccount } from 'wagmi';
import { formatUnits } from 'ethers';
import { fetchDelegatesAndBalances } from '@/app/actions/fetchDelegates';
import { delegatesAtom } from '@/app/atoms/delegates';
import { baseUserDataAtom } from '@/app/atoms/userData';
import { DelegateInfo } from '@/app/types/delegates';
import { useQuery } from '@tanstack/react-query';
import { proposalThresholdAtom } from '@/app/atoms/governorContract';
import { tokenInfoAtom } from '../atoms/token';
import { UserData } from '@/app/types/userData';
import { formatVotingPower } from '../helpers/formatVotingPower';

export const useInitializeDelegates = () => {
  const setDelegates = useSetAtom(delegatesAtom);
  const setBaseUserData = useSetAtom(baseUserDataAtom);
  const { address: userAddress } = useAccount();
  const proposalThreshold = useAtomValue(proposalThresholdAtom);
  const tokenInfo = useAtomValue(tokenInfoAtom);

  const { data: delegatesData } = useQuery<DelegateInfo[]>({
    queryKey: ['delegates'],
    queryFn: fetchDelegatesAndBalances,
    retry: 2,
  });

  useEffect(() => {
    if (userAddress) {
      setBaseUserData((prev: UserData) => ({
        ...prev,
        address: userAddress as `0x${string}`,
      }));
    }
  }, [userAddress, setBaseUserData]);

  useEffect(() => {
    if (delegatesData) {
      setDelegates(delegatesData);
    }
  }, [delegatesData, setDelegates]);

  useEffect(() => {
    if (userAddress && delegatesData && proposalThreshold) {
      const userDelegate = delegatesData.find(
        (delegate) => delegate.address === userAddress,
      );

      if (userDelegate) {
        const readableVotingPower = formatUnits(userDelegate.votingPower ?? '0', 18);

        const formattedVotingPower = formatVotingPower(userDelegate.votingPower!);

        const meetsProposalThreshold =
          !isNaN(Number(formattedVotingPower)) &&
          Number(formattedVotingPower) >= Number(proposalThreshold.replace(/,/g, ''));

        setBaseUserData((prev: UserData) => ({
          ...prev,
          address: userDelegate.address as `0x${string}`,
          votingPower: Number(readableVotingPower).toLocaleString(),
          delegatedTokens: formattedVotingPower || '',
          meetsProposalThreshold,
          delegateCount: 1,
          delegatesAddress: (userDelegate.delegators || []) as `0x${string}`[],
          delegatorsAddresses: delegatesData
            .filter((delegate) =>
              delegate.delegators?.includes(userAddress as `0x${string}`),
            )
            .map((delegate) => delegate.address as `0x${string}`),
          delegatorsCount: userDelegate.delegators ? userDelegate.delegators.length : 0,
          isUserSelfDelegated: (userDelegate.delegators || []).includes(
            userAddress as `0x${string}`,
          ),
          userHasVoted: false,
        }));
      }
    }
  }, [
    userAddress,
    delegatesData,
    setBaseUserData,
    proposalThreshold,
    tokenInfo.circulatingSupply,
  ]);
};
