import { ethers, formatUnits } from 'ethers';
import { governorABI } from '@/app/abi';
import { abbreviateValue } from '@/app/helpers/abbreviateValue';
import {
  VotingPeriodData,
  VotingDelayData,
  CirculatingSupplyData,
} from '@/app/types/governor';

const governorContractAddress = process.env.NEXT_PUBLIC_GOVERNOR_ADDRESS as `0x${string}`;
const provider = new ethers.JsonRpcProvider(process.env.NEXT_PUBLIC_PROVIDER_URL);

async function getGovernorContract() {
  return new ethers.Contract(governorContractAddress, governorABI, provider);
}

export async function fetchQuorum(): Promise<string> {
  try {
    const contract = await getGovernorContract();

    // Get the current blockchain timestamp
    const currentBlock = await provider.getBlock('latest');
    const currentTimestamp = currentBlock?.timestamp;

    // Call the quorum function with the current timestamp
    const data = await contract.quorum(currentTimestamp);

    // Format the result for readability
    const formattedQuorum = formatUnits(data, 18)
      .replace(/\.0$/, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedQuorum;
  } catch (error) {
    console.error('Error fetching quorum:', error);
    throw error;
  }
}

// Fetch Quorum Numerator
export async function fetchQuorumNumerator(): Promise<string> {
  try {
    const contract = await getGovernorContract();
    const data = await contract.quorumNumerator();
    return data.toString();
  } catch (error) {
    console.error('Error fetching quorum numerator:', error);
    throw error;
  }
}

// Fetch Quorum Denominator
export async function fetchQuorumDenominator(): Promise<string> {
  try {
    const contract = await getGovernorContract();
    const data = await contract.quorumDenominator();
    return data.toString();
  } catch (error) {
    console.error('Error fetching quorum denominator:', error);
    throw error;
  }
}

// Fetch Proposal Threshold
export async function fetchProposalThreshold(): Promise<string> {
  try {
    const contract = await getGovernorContract();
    const data = await contract.proposalThreshold();

    const formattedThreshold = formatUnits(data, 18)
      .replace(/\.0$/, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedThreshold;
  } catch (error) {
    console.error('Error fetching proposal threshold:', error);
    throw error;
  }
}

// Fetch Voting Period
export async function fetchVotingPeriod(): Promise<VotingPeriodData> {
  const contract = await getGovernorContract();
  const data = await contract.votingPeriod();
  const blocksPerDay = 7200;
  const votingPeriodInBlocks = parseInt(data.toString());
  const formattedVotingPeriod = formatVotingPeriod(votingPeriodInBlocks, blocksPerDay);
  return { votingPeriodInBlocks, formattedVotingPeriod };
}

// Fetch Voting Delay
export async function fetchVotingDelay(): Promise<VotingDelayData> {
  const contract = await getGovernorContract();
  const data = await contract.votingDelay();

  const votingDelayInBlocks = parseInt(data.toString(), 10);
  const averageBlockTimeInSeconds = 15;
  const votingDelayInSeconds = votingDelayInBlocks * averageBlockTimeInSeconds;

  const formattedVotingDelay = formatDelay(votingDelayInSeconds);
  return { formattedVotingDelay };
}

// Fetch Circulating Supply
export async function fetchCirculatingSupply(): Promise<CirculatingSupplyData> {
  const contract = await getGovernorContract();
  const data = await contract.circulatingSupply();

  const formattedSupply = formatUnits(data, 18);
  const supplyNumber = parseFloat(formattedSupply);
  const circulatingSupply = Math.round(supplyNumber).toLocaleString();
  const abbreviatedSupply = abbreviateValue(Math.round(supplyNumber));

  return { circulatingSupply, abbreviatedSupply };
}

// Fetch Token Address
export async function fetchTokenAddress(): Promise<string> {
  const contract = await getGovernorContract();
  const data = await contract.token();
  return data;
}

// Fetch Timelock Address
export async function fetchTimelockAddress(): Promise<string> {
  const contract = await getGovernorContract();
  const data = await contract.timelockControllerAddress();
  return data;
}

// Check if Account Can Vote
export async function canVote(
  proposalId: number,
  account: string,
): Promise<{ canVote: boolean; delegatedTokens: string }> {
  try {
    const contract = await getGovernorContract();
    const proposalSnapshot = await contract.proposalSnapshot(proposalId);

    const votingPower = await contract.getVotes(account, proposalSnapshot);
    const votingPowerBigInt = BigInt(votingPower.toString());
    const canVote = votingPowerBigInt > BigInt(0);

    const delegatedTokens = formatUnits(votingPower, 18);
    return { canVote, delegatedTokens };
  } catch (error) {
    console.error('Error in canVote function:', error);
    throw error;
  }
}

// Check if Account Has Voted
export async function hasVoted(proposalId: number, account: string): Promise<boolean> {
  const contract = await getGovernorContract();
  const hasVoted = await contract.hasVoted(proposalId, account);
  return hasVoted;
}

// Utility Functions
function formatVotingPeriod(blocks: number | null, blocksPerDay: number): string | null {
  if (blocks === null) return null;

  const days = blocks / blocksPerDay;
  const hours = days * 24;

  if (days >= 365) return `${(days / 365).toFixed(2).replace(/\.00$/, '')} years`;
  if (days >= 30) return `${(days / 30).toFixed(2).replace(/\.00$/, '')} months`;
  if (days >= 7) return `${(days / 7).toFixed(2).replace(/\.00$/, '')} weeks`;
  if (days >= 1) return `${days.toFixed(2).replace(/\.00$/, '')} days`;
  if (hours >= 1) return `${hours.toFixed(2).replace(/\.00$/, '')} hours`;
  return `${(hours * 60).toFixed(2).replace(/\.00$/, '')} min`;
}

function formatDelay(seconds: number): string | null {
  if (seconds === null) return null;

  const units = [
    { unit: 'years', value: 365 * 24 * 60 * 60 },
    { unit: 'months', value: 30 * 24 * 60 * 60 },
    { unit: 'weeks', value: 7 * 24 * 60 * 60 },
    { unit: 'days', value: 24 * 60 * 60 },
    { unit: 'hours', value: 60 * 60 },
    { unit: 'min', value: 60 },
    { unit: 'seconds', value: 1 },
  ];

  for (const { unit, value } of units) {
    if (seconds >= value) {
      const number = seconds / value;
      return `${number.toFixed(2).replace(/\.00$/, '')} ${unit}`;
    }
  }
  return null;
}
