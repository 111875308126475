import { useEffect } from 'react';
import { useAtom } from 'jotai';
import { userSettingsAtom } from '@/app/atoms/users';
import { useAccount } from 'wagmi';

export const useGetOneUser = () => {
  const [settings, setSettings] = useAtom(userSettingsAtom);
  const { address, isConnected } = useAccount();
  const usersAddress = address as `0x${string}`;

  const fetchData = async () => {
    try {
      const response = await fetch(`/api/get-one-user?walletId=${usersAddress}`);
      const data = await response.json();

      setSettings(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (isConnected) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersAddress, isConnected]);

  return { settings, fetchData };
};
