'use client';

import { atom } from 'jotai';
import { ethers } from 'ethers';
import { proposalThresholdAtom } from '@/app/atoms/governorContract';
import { delegatesAtom } from '@/app/atoms/delegates';
import { UserData } from '@/app/types/userData';
import { DelegateInfo } from '@/app/types/delegates';
import { formatUnits } from 'ethers';
import { formatVotingPower } from '@/app/helpers/formatVotingPower';
import { Vote } from '../types/proposals';

export const baseUserDataAtom = atom<UserData>({
  address: ethers.ZeroAddress as `0x${string}`,
  delegateCount: 0,
  delegatesAddress: [],
  delegatorsAddresses: [],
  delegatorsCount: 0,
  isUserSelfDelegated: false,
  votingPower: '0',
  delegatedTokens: '0',
  meetsProposalThreshold: false,
  userHasVoted: false,
});

// Trigger atom to notify of delegate updates
export const delegatesUpdateTriggerAtom = atom(
  (get) => get(delegatesAtom),
  (get, set, newDelegates: DelegateInfo[]) => {
    set(delegatesAtom, newDelegates);
    const updatedUserData = get(userDataWithDelegatesAtom);
    set(userDataAtom, updatedUserData);
  },
);

export const userDataWithDelegatesAtom = atom((get): UserData => {
  const baseUserData = get(baseUserDataAtom);
  const delegates = get(delegatesAtom);
  const userAddress = baseUserData.address;
  const proposalThreshold = get(proposalThresholdAtom);

  if (!proposalThreshold) {
    return baseUserData;
  }

  const userDelegate = delegates.find((delegate) => delegate.address === userAddress);

  if (!userDelegate) {
    return baseUserData;
  }

  // Format voting power and ensure it's a proper number
  const readableVotingPower = formatUnits(userDelegate.votingPower!, 18);
  let formattedVotingPower = formatVotingPower(readableVotingPower) || '0';
  formattedVotingPower = formattedVotingPower.replace(/,/g, '');

  // Ensure both values are numbers and proceed with comparison
  const meetsProposalThreshold =
    !isNaN(Number(formattedVotingPower)) &&
    Number(formattedVotingPower) >= Number(proposalThreshold.replace(/,/g, ''));

  return {
    ...baseUserData,
    address: userAddress,
    delegateCount: 1,
    delegatesAddress: userDelegate.delegators || [],
    delegatorsAddresses: delegates
      .filter((delegate) => delegate.delegators?.includes(userAddress))
      .map((delegate) => delegate.address),
    delegatorsCount: (userDelegate.delegators || []).length,
    isUserSelfDelegated: (userDelegate.delegators || []).includes(userAddress),
    votingPower: Number(formattedVotingPower).toLocaleString(),
    meetsProposalThreshold,
    delegatedTokens: userDelegate.votingPower || '0',
    userHasVoted: false,
  };
});

// Writable derived atom to automatically update userData when delegates or proposal threshold changes
export const userDataAtom = atom(
  (get) => get(userDataWithDelegatesAtom),
  (get, set, updatedUserData: UserData) => set(baseUserDataAtom, updatedUserData),
);

export const userVotesAtom = atom<Vote[]>([]);
export const userVotesLoadingAtom = atom(true);
