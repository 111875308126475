import { ProposalCard } from '@/app/types/proposals';
import { getProposalState } from '@/app/helpers/proposalLogHelpers';
import { Address, isAddress } from 'viem';

const fetchFromApi = async (url: string): Promise<any> => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch data from ${url}`);
  }
  return response.json();
};

const isValidDate = (dateString: string) => {
  const date = new Date(parseInt(dateString) * 1000);
  return isNaN(date.getTime()) ? null : date;
};

export const formatProposalDetails = (proposals: any) => {
  return proposals.map((proposal: any) => {
    if (!isAddress(proposal.proposer))
      throw new Error('Proposal address is badly formatted.');

    return {
      proposal_id: proposal.proposal_id,
      title: proposal.title,
      proposer: proposal.proposer as Address,
      snapshot: proposal.snapshot,
      state: getProposalState(proposal.state),
      created_at: new Date(proposal.created_at),
      description: proposal.description || 'No Description Available',
      targets: (proposal.targets as Address[]) || [],
      decodedCalldatas: proposal.decoded_calldatas || [],
      calldatas: proposal.calldatas.map((calldata: string) => ({
        method: '',
        types: [],
        values: [],
        encodedData: calldata,
      })),
      vals: proposal.vals || [],

      votes_for: proposal.votes_for || '0',
      votes_against: proposal.votes_against || '0',
      votes_abstain: proposal.votes_abstain || '0',
      vote_end: isValidDate(proposal.vote_end_timestamp),
      vote_start: isValidDate(proposal.vote_start_timestamp),
      quorum: (BigInt(proposal.quorum) / BigInt(10 ** 18)).toLocaleString(),
      voters: proposal.voters || 0,
    };
  });
};

export const fetchProposals = async (): Promise<{
  proposals: ProposalCard[];
  totalProposals: number;
  passed: number;
  failed: number;
}> => {
  try {
    const data = await fetchFromApi(process.env.NEXT_PUBLIC_PROPOSALS_API!);
    const proposals = formatProposalDetails(data);

    const totalProposals = data.length;
    const passed = data.filter((proposal: any) => proposal.state === 4).length;
    const failed = data.filter((proposal: any) => proposal.state === 3).length;

    return {
      proposals,
      totalProposals,
      passed,
      failed,
    };
  } catch (error) {
    console.error('Error fetching proposals:', error);
    throw error;
  }
};
