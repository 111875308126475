'use client';

import { useEffect, useState, useRef } from 'react';
import { useAccount } from 'wagmi';
import { useFetchProposals } from '@/app/hooks/useFetchProposals';
import { useTokenContext } from '@/app/context/TokensContext';

type WebSocketMessage = {
  data: string;
  delegates?: boolean;
  proposals?: boolean;
  votes?: boolean;
};

type WebSocketListeners = {
  onProposalsUpdated?: () => void;
};

// export const useWebSocket = (): WebSocketMessage[] => {
export const useWebSocket = (listeners: WebSocketListeners = {}) => {
  const [messages, setMessages] = useState<WebSocketMessage[]>([]);
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const { address } = useAccount();
  const addressRef = useRef(address);
  const { mutation } = useFetchProposals();
  const { reFetchDelegates } = useTokenContext();
  const listenersRef = useRef(listeners);

  useEffect(() => {
    listenersRef.current = listeners;
  }, [listeners]);

  useEffect(() => {
    addressRef.current = address;
  }, [address]);

  useEffect(() => {
    const connectWebSocket = () => {
      const ws = new WebSocket(process.env.NEXT_PUBLIC_WSS_CONNECTION!);

      ws.onmessage = async (event: MessageEvent) => {
        const eventData = event.data;
        const { delegates, proposals, votes } = JSON.parse(eventData);

        if (!!proposals || !!votes) {
          if (listeners.onProposalsUpdated) {
            listeners.onProposalsUpdated();
          }
          await mutation.mutate();
        }

        if (!!delegates) {
          await reFetchDelegates();
        }
      };

      ws.onclose = () => {
        setTimeout(() => {
          connectWebSocket();
        }, 5000);
      };

      ws.onerror = (error: Event) => {
        console.error('WebSocket Error:', error);
        ws.close();
      };

      setSocket(ws);
    };

    connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return messages;
};
