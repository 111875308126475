'use client';

import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { ethers, formatUnits } from 'ethers';
import { useAccount } from 'wagmi';
import { useSetAtom, useAtom, useAtomValue } from 'jotai';
import { tokenABI } from '@/app/abi';
import { useEthersSigner } from '@/app/connection/ethersAdapters';
import { getTokenBalanceOf } from '@/app/helpers/contracts/token/read';
import { crank } from '@/app/helpers/crank';
import {
  ApproveParams,
  TransferParams,
  TransferFromParams,
  IncreaseAllowanceParams,
  DecreaseAllowanceParams,
  MintParams,
  BurnParams,
  DelegateParams,
  DelegateBySigParams,
} from '@/app/types/token';
import { fetchDelegatesAndBalances } from '@/app/actions/fetchDelegates';
import { tokenInfoAtom } from '@/app/atoms/token';
import { delegatesAtom, delegatorsAtom } from '@/app/atoms/delegates';

const tokenContractAddress = process.env.NEXT_PUBLIC_TOKEN_ADDRESS as `0x${string}`;

interface TokenContextProps {
  tokenApprove: (params: ApproveParams) => Promise<ethers.ContractTransaction>;
  tokenTransfer: (params: TransferParams) => Promise<ethers.ContractTransaction>;
  tokenTransferFrom: (params: TransferFromParams) => Promise<ethers.ContractTransaction>;
  tokenIncreaseAllowance: (
    params: IncreaseAllowanceParams,
  ) => Promise<ethers.ContractTransaction>;
  tokenDecreaseAllowance: (
    params: DecreaseAllowanceParams,
  ) => Promise<ethers.ContractTransaction>;
  tokenMint: (params: MintParams) => Promise<ethers.ContractTransaction>;
  tokenBurn: (params: BurnParams) => Promise<ethers.ContractTransaction>;
  tokenDelegate: (params: DelegateParams) => Promise<{
    txHash: `0x${string}`;
    blockNumber: number | null;
    error: string | null;
  }>;
  tokenDelegateBySig: (
    params: DelegateBySigParams,
  ) => Promise<ethers.ContractTransaction>;
  reFetchDelegates: () => Promise<void>;
}

const TokenContext = createContext<TokenContextProps | undefined>(undefined);

interface OperationProviderProps {
  children: ReactNode;
}

export const TokenContextProvider: React.FC<OperationProviderProps> = ({ children }) => {
  const signer = useEthersSigner();
  const setDelegates = useSetAtom(delegatesAtom);
  const setUserDelegate = useSetAtom(delegatorsAtom);
  const setInfo = useSetAtom(tokenInfoAtom);
  const [isFetchingDelegates, setIsFetchingDelegates] = useState(false);

  const reFetchDelegates = async () => {
    if (isFetchingDelegates) return;
    setIsFetchingDelegates(true);

    try {
      // Fetch delegates from the API
      const delegates = await fetchDelegatesAndBalances();
      setDelegates(delegates);

      // Set delegator information based on delegate addresses
      const delegateAddresses = delegates.map((delegate) => delegate.address);
      const delegators = delegateAddresses.map((address) => ({
        address,
        count: delegateAddresses.length || 0,
      }));

      setUserDelegate(delegators as any);

      // Fetch token balances for each delegate
      const balancePromises = delegates.map(async (delegate) => {
        try {
          const { balance, decimals } = await getTokenBalanceOf(
            delegate.address as `0x${string}`,
          );
          if (balance && decimals) {
            const formattedBalance = formatUnits(balance, decimals);
            setInfo((prev) => ({
              ...prev,
              [delegate.address]: {
                balance: formattedBalance,
              },
            }));
          }
        } catch (error) {
          console.error(
            `Error fetching token balance for delegate ${delegate.address}:`,
            error,
          );
        }
      });

      await Promise.all(balancePromises);
    } catch (error) {
      console.error('Error in reFetchDelegates:', error);
    } finally {
      setIsFetchingDelegates(false);
    }
  };

  const tokenApprove = async (params: ApproveParams) => {
    const contract = new ethers.Contract(tokenContractAddress, tokenABI, signer);
    const transaction = await contract.approve(params.spender, params.amount);
    return transaction;
  };

  const tokenTransfer = async (params: TransferParams) => {
    const contract = new ethers.Contract(tokenContractAddress, tokenABI, signer);
    const transaction = await contract.transfer(params.to, params.amount);
    return transaction;
  };

  const tokenTransferFrom = async (params: TransferFromParams) => {
    const contract = new ethers.Contract(tokenContractAddress, tokenABI, signer);
    const transaction = await contract.transferFrom(
      params.from,
      params.to,
      params.amount,
    );
    return transaction;
  };

  const tokenIncreaseAllowance = async (params: IncreaseAllowanceParams) => {
    const contract = new ethers.Contract(tokenContractAddress, tokenABI, signer);
    const transaction = await contract.increaseAllowance(
      params.spender,
      params.addedValue,
    );
    return transaction;
  };

  const tokenDecreaseAllowance = async (params: DecreaseAllowanceParams) => {
    const contract = new ethers.Contract(tokenContractAddress, tokenABI, signer);
    const transaction = await contract.decreaseAllowance(
      params.spender,
      params.subtractedValue,
    );
    return transaction;
  };

  const tokenMint = async (params: MintParams) => {
    const contract = new ethers.Contract(tokenContractAddress, tokenABI, signer);
    const transaction = await contract.mint(params.to, params.amount);
    return transaction;
  };

  const tokenBurn = async (params: BurnParams) => {
    const contract = new ethers.Contract(tokenContractAddress, tokenABI, signer);
    const transaction = await contract.burn(params.amount);
    return transaction;
  };

  const tokenDelegate = async (params: DelegateParams) => {
    const contract = new ethers.Contract(tokenContractAddress, tokenABI, signer);
    const transaction = await contract.delegate(params.delegatee);
    const receipt = await transaction.wait();

    // Triggering the API data update
    crank();

    return {
      txHash: transaction.hash,
      blockNumber: Number(receipt.blockNumber),
      error: null,
    };
  };

  const tokenDelegateBySig = async (params: DelegateBySigParams) => {
    const contract = new ethers.Contract(tokenContractAddress, tokenABI, signer);
    const transaction = await contract.delegateBySig(
      params.delegatee,
      params.nonce,
      params.expiry,
      params.v,
      params.r,
      params.s,
    );

    // Triggering the API data update
    crank();

    return transaction;
  };

  return (
    <TokenContext.Provider
      value={{
        tokenApprove,
        tokenTransfer,
        tokenTransferFrom,
        tokenIncreaseAllowance,
        tokenDecreaseAllowance,
        tokenMint,
        tokenBurn,
        tokenDelegate,
        tokenDelegateBySig,
        reFetchDelegates,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};

export const useTokenContext = () => {
  const context = useContext(TokenContext);
  if (!context) {
    throw new Error('useOperations must be used within a TokenContext');
  }
  return context;
};
