import { cookieStorage, createStorage } from 'wagmi';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { mainnet, sepolia, Chain } from 'wagmi/chains';

export const projectId = process.env.NEXT_PUBLIC_PROJECT_ID!;

if (!projectId) throw new Error('Project ID is not defined');

const metadata = {
  name: 'WinVote',
  description: 'The Governance Voting Platform',
  url: 'https://winvote.io',
  icons: [''],
};

// Determine the chain based on the environment variable
const chainName = process.env.NEXT_PUBLIC_CHAIN;

let selectedChain: Chain | undefined;

if (chainName === 'mainnet') {
  selectedChain = mainnet;
} else if (chainName === 'sepolia') {
  selectedChain = sepolia;
} else {
  throw new Error('Unsupported or undefined chain specified in NEXT_PUBLIC_CHAIN');
}

if (!selectedChain) {
  throw new Error('Chain selection failed. Please check your configuration.');
}

const chains: readonly [Chain, ...Chain[]] = [selectedChain];

export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
  ssr: true,
  storage: createStorage({
    storage: cookieStorage,
  }),
});
