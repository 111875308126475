import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { fetchProposals } from '@/app/actions/fetchProposals';
import { useSetAtom, useAtomValue } from 'jotai';
import {
  allProposalsAtom,
  proposalCountAtom,
  totalVotesAtom,
  initialProposalsAtom,
  passedProposalsAtom,
} from '@/app/atoms/proposals';
import { ProposalCard } from '@/app/types/proposals';

export const useFetchProposals = () => {
  const setAllProposals = useSetAtom(allProposalsAtom);
  const setProposalCount = useSetAtom(proposalCountAtom);
  const setTotalVotes = useSetAtom(totalVotesAtom);
  const setInitialProposals = useSetAtom(initialProposalsAtom);
  const setPassedProposals = useSetAtom(passedProposalsAtom);

  const initialProposals = useAtomValue(initialProposalsAtom);
  const [loading, setLoading] = useState(true);
  const [isInitialLoaded, setIsInitialLoaded] = useState(false);

  const mutation = useMutation({
    mutationFn: async () => {
      const initialResults = await fetchProposals();
      const { proposals, totalProposals, passed, failed } = initialResults;

      // Set initial proposals
      if (proposals.length) {
        setInitialProposals(proposals.slice(0, 3));
        setAllProposals(proposals);
      }

      const totalVotes = proposals.reduce(
        (acc: number, proposal: ProposalCard) => acc + parseInt(proposal.votes_for),
        0,
      );

      setProposalCount({
        total: totalProposals,
        passed,
        failed,
      });

      setTotalVotes(totalVotes);
      setPassedProposals(proposals.filter((p) => p.state.text === 'Succeeded'));
      setIsInitialLoaded(true);
      setLoading(false);
    },
    onError: (error) => {
      console.error('Error fetching proposals:', error);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (!initialProposals.length && !isInitialLoaded) {
      mutation.mutate();
    } else if (isInitialLoaded) {
      setLoading(false);
    }
    /* eslint-disable-next-line */
  }, [initialProposals.length, isInitialLoaded]);

  return { loading, mutation };
};

export default useFetchProposals;
